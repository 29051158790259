<template>
    <div class="main">

        <div class="actionBar">
            <a-space>
                <a-tree-select allowClear v-model="listParams.cat_id" style="width: 140px" :tree-data="classify"
                    placeholder="分类" :replaceFields="{ value: 'id' }" />

                <!-- <a-tree-select allowClear v-model="listParams.status" style="width: 100px" :tree-data="check_status"
                    placeholder="状态" /> -->

                <a-range-picker style="width: 280px" v-model="time" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"
                    :locale="locale" @change="timeChange">
                </a-range-picker>

                <a-select allowClear v-model="listParams.flag" style="width: 140px" placeholder="标签">
                    <a-select-option value="1">
                        1
                    </a-select-option>
                    <a-select-option value="2">
                        2
                    </a-select-option>
                    <a-select-option value="3">
                        3
                    </a-select-option>
                    <a-select-option value="4">
                        4
                    </a-select-option>
                </a-select>

                <a-input-search v-model="listParams.nickname" placeholder="用户名称" enter-button="查询" style="width: 250px"
                    allowClear @search="onSearch" />

                <a-button type="primary" @click="resetting">
                    重置</a-button>

                <a-button icon="download" type="primary" @click="downloadR">
                    资料导出</a-button>
            </a-space>
        </div>

        <a-table bordered :columns="columns" :data-source="data" rowKey="id" :scroll="{ x: 1800 }" :pagination="{
            total: count,
            current: listParams.page,
            pageSize: listParams.limit,
            showTotal: (total) => `共 ${total} 条`,
        }" @change="pageChange">

            <span slot="user_name" slot-scope="text, record, index">
                <a  @click="showMoadl(record)">{{ text }}</a>
            </span>

            <span slot="flag" slot-scope="text, record">

                <a-select :value="record.flag ? record.flag : undefined" @focus="flagParams.id = record.id"
                    style="width: 80px" @change="flagHandleChange" placeholder="标签">
                    <a-select-option value="1">
                        1
                    </a-select-option>
                    <a-select-option value="2">
                        2
                    </a-select-option>
                    <a-select-option value="3">
                        3
                    </a-select-option>
                    <a-select-option value="4">
                        4
                    </a-select-option>
                </a-select>

            </span>

            <span slot="check_status" slot-scope="text">
                <a-tag color="#999" v-if="text == 1">
                    待审核
                </a-tag>
                <a-tag color="pink" v-else-if="text == 2">
                    待确认
                </a-tag>
                <a-tag color="blue" v-else-if="text == 3">
                    已确认
                </a-tag>
                <a-tag color="red" v-else-if="text == 4">
                    已驳回
                </a-tag>
                <a-tag color="green" v-else-if="text == 5">
                    已打款
                </a-tag>
            </span>

            <span slot="action" slot-scope="text, record">
                <a-space>
                    <a-button size="small" type="primary" @click="showModal(record)">查看</a-button>
                    <a-button size="small" type="primary" @click="showDrawer(record)"
                        v-if="record.check_status == 1">审核</a-button>


                    <!-- <a-button :type="record.check_status == 1 ? 'primary' : ''" size="small" @click="goDetail(record.id)">{{
                        record.check_status == 1 ? '审核' : '详情' }}</a-button> -->
                    <a-popconfirm title="确定删除？" ok-text="确定" cancel-text="取消" @confirm="confirm(record)"
                        v-if="record.check_status == 4">
                        <a-button type="danger" size="small">删除</a-button>
                    </a-popconfirm>
                </a-space>
            </span>
        </a-table>

        <a-modal :title="listDetail.title" :visible="visible2" @cancel="visible2 = false" :footer="null" :width="1000">

            <h3>上传视频列表</h3>
            <a-table bordered :columns="columns2" :data-source="listDetail.videos.list" rowKey="id" :pagination="false"
                v-if="listDetail != ''">
                <span slot="status" slot-scope="text">
                    <a-tag color="#999" v-if="text == 0">
                        待审核
                    </a-tag>
                    <a-tag color="blue" v-else-if="text == 1">
                        已通过
                    </a-tag>
                    <a-tag color="red" v-else-if="text == 2">
                        已驳回
                    </a-tag>
                </span>

                <span slot="action" slot-scope="text, record">
                    <a-space>
                        <a-button size="small" @click="view(record)">查看</a-button>
                        <a-button size="small" @click="download(record)">下载</a-button>
                    </a-space>
                </span>
            </a-table>

            <h3 style="padding-top: 30px;">上传文件列表</h3>

            <a-table bordered :columns="columns2" :data-source="listDetail.files.list" rowKey="id" :pagination="false"
                v-if="listDetail != ''">
                <span slot="status" slot-scope="text">
                    <a-tag color="#999" v-if="text == 0">
                        待审核
                    </a-tag>
                    <a-tag color="blue" v-else-if="text == 1">
                        已通过
                    </a-tag>
                    <a-tag color="red" v-else-if="text == 2">
                        已驳回
                    </a-tag>
                </span>

                <span slot="action" slot-scope="text, record">
                    <a-space>
                        <a-button size="small" @click="download(record)">查看</a-button>
                        <!-- <a-button size="small" @click="view(record)">查看</a-button> -->
                        <a-button size="small" @click="download(record)">下载</a-button>
                    </a-space>
                </span>
            </a-table>
        </a-modal>

        <a-drawer :title="`批量审核：` + listDetail.title + `(资料名称)`" placement="right" :closable="false" :visible="visible"
            @close="onClose" width="500" :after-visible-change="afterVisibleChange">
            <div v-for="(item, index) in auditData.detail" :key="'auditData' + index" style="margin-bottom: 10px;">
                <a-card :title="item.title" style="width: 450px">
                    <span slot="extra">待审核分值:{{ item.start_score }}</span>

                    <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                        <a-form-item label="最终分值">
                            <a-input-number v-model="item.score" :min="0" :max="item.start_score"
                                :disabled="item.status == 2" />
                        </a-form-item>
                        <a-form-item label="审核">
                            <a-radio-group v-model="item.status" @change="statusChange(index, item.start_score)">
                                <a-radio :value="1">
                                    通过
                                </a-radio>
                                <a-radio :value="2">
                                    驳回
                                </a-radio>
                            </a-radio-group>
                        </a-form-item>

                        <a-form-item label="驳回理由" v-if="item.status == 2">
                            <a-textarea v-model="item.reason" placeholder="请写驳回理由(必填)"
                                :auto-size="{ minRows: 3, maxRows: 5 }" />
                            <p style="color: red;" v-if="item.reason.length == 0">如果审核状态为驳回,驳回理由(必填)</p>
                        </a-form-item>
                    </a-form>
                </a-card>
            </div>
            <div style="display: flex;justify-content: space-between;align-items: center;margin: 30px 0;">

                <div style="font-size: 24px;">
                    确认分值合计：{{ fullName }}
                </div>
                <a-popconfirm title="确定分值正确？" ok-text="确定" cancel-text="取消" @confirm="auditDataConfirm">
                    <a-button type="primary">确定</a-button>
                </a-popconfirm>
            </div>
        </a-drawer>

        <!-- 上传人信息 -->
        <a-modal title="上传人资料" :visible="visible3" @cancel="visible3 = false" :width="1000" :footer="null">
            <a-card title="用户资料" style="width: 900px">
                <a-row>
                    <a-col :span="8">
                        姓名：{{ uploaderInformation.user_realname }}
                    </a-col>
                    <a-col :span="8">
                        性别：{{ uploaderInformation.sex == 1 ? '男' : '女' }}
                    </a-col>
                    <a-col :span="8">
                        昵称：{{ uploaderInformation.user_nickname }}
                    </a-col>
                </a-row>
                <br>
                <a-row>
                    <a-col :span="8">
                        手机号：{{ uploaderInformation.mobile }}
                    </a-col>
                    <a-col :span="8">
                        账号：{{ uploaderInformation.user_login }}
                    </a-col>
                    <a-col :span="8">
                        身份证：{{ uploaderInformation.id_card }}
                    </a-col>
                </a-row>
                <br>
                <a-row>
                    <a-col :span="8">
                        银行卡号：{{ uploaderInformation.bank_no }}
                    </a-col>
                    <a-col :span="16">

                        开户行(精确到支行)：{{ uploaderInformation.bank_name }}
                    </a-col>

                </a-row>
            </a-card>
        </a-modal>

    </div>
</template>

<script>
import {
    materialIndex,
    materialDelete,
    materialCatShowList,
    materialCheckl,
    materialFlag
} from "@/apis/index";
import { materialExportResult } from "@/apis/down";
import axios from "axios";
import moment from "moment";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";

//  查看表格
const columns2 = [
    {
        title: "资料名称",
        dataIndex: "title",
        scopedSlots: { customRender: "title" },
        width: 200
    },

    {
        title: "文件类型",
        dataIndex: "cat_name",
        scopedSlots: { customRender: "cat_name" },
        width: 110
    },
    {
        title: "审核分值",
        dataIndex: "start_score",
        scopedSlots: { customRender: "start_score" },
        width: 100
    },
    {
        title: "审核状态",
        dataIndex: "status",
        scopedSlots: { customRender: "status" },
        width: 100

    },
    {
        title: "驳回原因",
        dataIndex: "reason",
        scopedSlots: { customRender: "reason" },

    },
    {
        title: "最终分值",
        dataIndex: "score",
        scopedSlots: { customRender: "score" },
        width: 100
    },
    {
        title: "操作",
        key: "action",
        scopedSlots: { customRender: "action" },
        width: 140
    }
]

const columns = [

    {
        title: "资料名称",
        dataIndex: "title",
        scopedSlots: { customRender: "title" },
        width: 200,
        fixed: 'left',
    },
    {
        title: "标签",
        key: "flag",
        scopedSlots: { customRender: "flag" },
        width: 120,
    },
    {
        title: "上传人",
        dataIndex: "user_name",
        scopedSlots: { customRender: "user_name" },
        width: 160,
    },
    {
        title: "视频数量",
        dataIndex: "videos",
        scopedSlots: { customRender: "videos" },
        customRender: (text) => {
            return text.count
        },
        width: 100,
        align: 'center'
    },
    {
        title: "文件数量",
        dataIndex: "files",
        scopedSlots: { customRender: "files" },
        customRender: (text) => {
            return text.count
        },
        width: 100,
        align: 'center'

    },
    {
        title: "上传时间",
        dataIndex: "create_time",
        scopedSlots: { customRender: "create_time" },
        customRender: (text) => {
            // return text.count
            return moment(Number(text + "000")).format("YYYY-MM-DD HH:mm:ss");

        },
         width: 180,
    },
    {
        title: "审核时间",
        dataIndex: "check_time",
        scopedSlots: { customRender: "check_time" },
        customRender: (text) => {
            if (text != 0) {
                return moment(Number(text + "000")).format("YYYY-MM-DD HH:mm:ss");
            } else {
                return ''
            }
        },
        width: 180,
    },
    {
        title: "确认时间",
        dataIndex: "sure_time",
        scopedSlots: { customRender: "sure_time" },
        customRender: (text) => {
            if (text != 0) {
                return moment(Number(text + "000")).format("YYYY-MM-DD HH:mm:ss");
            } else {
                return ''
            }
        },
        width: 180,
    },
    {
        title: "打款时间",
        dataIndex: "pay_time",
        scopedSlots: { customRender: "pay_time" },
        customRender: (text) => {
            if (text != 0) {
                return moment(Number(text + "000")).format("YYYY-MM-DD HH:mm:ss");
            } else {
                return ''
            }
        },
        width: 180,
    },

    {
        title: "状态",
        dataIndex: "check_status",
        scopedSlots: { customRender: "check_status" },
        width: 120,
    },
    {
        title: "审核员",
        dataIndex: "admin_name",
        scopedSlots: { customRender: "admin_name" },
        width: 120,
    },
    {
        title: "最终分值",
        dataIndex: "score",
        scopedSlots: { customRender: "score" },
        fixed: 'right',
        width: 100,
    },
    {
        title: "操作",
        key: "action",
        scopedSlots: { customRender: "action" },
        width: 160,
        fixed: 'right',
    },
];

const check_status = [
    {
        title: "全部",
        value: 0,
    },
    {
        title: "待审核",
        value: 1,
    },
    {
        title: "待确认",
        value: 2,
    },
    {
        title: "已确认",
        value: 3,
    },
    {
        title: "已驳回",
        value: 4,
    },
    {
        title: "已打款",
        value: 5,
    },
];

export default {
    data() {
        return {
            columns2,
            columns,
            data: [],
            check_status,
            locale,
            time: [],
            listParams: {
                nickname: '',
                status: 3,
                cat_id: undefined,
                start_time: '',
                end_time: '',
                page: 1,
                limit: 10,
                flag: undefined
            },
            count: 0,
            classify: [],

            // 抽屉
            visible: false,

            listDetail: '',


            auditData: {
                id: '',
                detail: []
            },

            // 对话框
            visible2: false,

            // 标签
            flagParams: {
                id: '',
                flag: '',
            },

            visible3: false,
            uploaderInformation: ''
        }
    },


    created() {
        materialCatShowList().then(res => {
            this.classify = res.data.list
        })
        this.getmaterialIndex()
    },
    activated() {
        this.getmaterialIndex()
    },

    // watch:{
    //     auditData(newVal,oldVal){
    //        console.log('改变了')
    //     }
    // },
    computed: {

        fullName() {
            let d = 0
            this.auditData.detail.forEach(item => {
                d += item.score
            })

            return d
        }
    },

    methods: {
        // 
        resetting() {
            this.listParams = {
                nickname: '',
                status: 3,
                cat_id: undefined,
                start_time: '',
                end_time: '',
                page: 1,
                limit: 10,
                flag: undefined
            }
            this.time = []
            this.getmaterialIndex()
        },
        // 
        downloadR() {
            materialExportResult({ ...this.listParams, is_finance: 0 }).then((res) => {
                if (res.status == 200) {
                    let blob = new Blob([res.data], {
                        type: `application/csv`, //word文档为msword,pdf文档为pdf
                    });
                    let objectUrl = URL.createObjectURL(blob);
                    let link = document.createElement("a");
                    let fname = `资料列表.csv`; //下载文件的名字
                    link.href = objectUrl;
                    link.setAttribute("download", fname);
                    document.body.appendChild(link);
                    link.click();
                }
            });
        },


        // 上传人信息
        showMoadl(rec) {
            this.visible3 = true
            this.uploaderInformation = rec.user
            // console.log(rec)
        },


        statusChange(index, start_score) {

            if (this.auditData.detail[index].status == 2) {
                this.auditData.detail[index].score = 0
            } else {
                this.auditData.detail[index].score = start_score
            }



            // console.log(index)
        },

        flagHandleChange(e) {
            this.flagParams.flag = e
            materialFlag(this.flagParams).then(res => {
                console.log(res)
                if (res.code == 1) {
                    this.getmaterialIndex()
                }
            })

        },

        // 对话框查看
        showModal(rec) {
            this.visible2 = true
            this.listDetail = rec
        },

        view(rec) {
            window.open(rec.file_url, '_blank')
        },

        download(item) {
            // console.log(rec)

            axios({
                method: 'GET',
                url: item.file_url,
                responseType: 'blob'
            }).then(res => {
                const href = URL.createObjectURL(res.data)
                const link = document.createElement('a')
                link.href = href;
                link.setAttribute('download', item.title);
                document.body.appendChild(link);
                link.click();
            })

        },


        // 抽屉审核
        auditDataConfirm() {
            let flag = true

            this.auditData.detail.forEach((item, index) => {
                if (item.status == 2 && item.reason.length == 0) {
                    this.$message.error(`第${index + 1}个审核资料驳回理由没有填写`);
                    return flag = false
                }
            })

            if (flag) {
                materialCheckl(this.auditData).then(res => {
                    if (res.code == 1) {
                        this.visible = false
                        this.getmaterialIndex()
                        this.$message.success(`审核成功`);
                    }
                })
            }


            // console.log('确定')
        },

        afterVisibleChange(val) {
            console.log('visible', val);
            if (!val) {
                this.auditData = {
                    id: '',
                    detail: []
                }
            }
        },
        showDrawer(rec) {
            this.visible = true;
            this.listDetail = rec
            this.auditData.id = rec.id
            if (rec.videos.count > 0) {
                rec.videos.list.forEach(item => {
                    this.auditData.detail.push({
                        id: item.id,
                        score: item.start_score,
                        status: 1,
                        reason: '',
                        start_score: item.start_score,
                        title: item.title
                    })
                })
            }

            if (rec.files.count > 0) {
                rec.files.list.forEach(item => {
                    this.auditData.detail.push({
                        id: item.id,
                        score: item.start_score,
                        status: 1,
                        reason: '',
                        start_score: item.start_score,
                        title: item.title
                    })
                })
            }



            // console.log(rec)
        },


        onClose() {
            this.visible = false;
        },

        // goDetail(id) {
        //     this.$router.push({ path: "/DataAdministration/DataAdministrationDetail", query: { id } });
        // },
        confirm(rec) {
            materialDelete({ id: rec.id }).then(res => {
                if (res.code == 1) {
                    this.$message.success("删除成功");
                    this.getmaterialIndex()
                }
            })
        },
        onSearch() {
            this.listParams.page = 1
            this.getmaterialIndex()

        },
        timeChange(e) {
            this.listParams.start_time = e[0]
            this.listParams.end_time = e[1]
        },
        getmaterialIndex() {
            materialIndex(this.listParams).then(res => {
                this.data = res.data.list
                this.count = res.data.count
            })
        },
        pageChange(a) {
            this.listParams.page = a.current;
            this.getmaterialIndex()
        }
    }
}
</script>

<style lang="less" scoped>
.main {
    .actionBar {
        padding: 30px 0;
    }
}
</style>